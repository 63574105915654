<template>
  <DgText :data="text" />
</template>
<script>
import MixinFixWidget from "@/mixins/fixWidget";
import { DgText } from "@engenharia/dashgoo-widgets";

export default {
  name: "RenderWidgetDynamicSummary",
  mixins: [MixinFixWidget],
  components: {
    DgText,
  },
  computed: {
    text() {
      const value = this.widget?.data?.value;
      const isText = typeof value === "string";
      return isText ? value : "";
    },
  },
};
</script>
